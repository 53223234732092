import React, { Component } from "react"
import Header from "./header"
import ANSDPADFooter from "./footer"

import "./stylesheets/plugins.css"
import "./stylesheets/style.css"
import "./stylesheets/slick-theme-min.css"
import "./stylesheets/slick.min.css"
import Helmet from "react-helmet"

interface Props {
  children?: any;
  headerElement: JSX.Element;
  scrollStepInPx: number;
  delayInMs: number;
}

type State = {
  thePosition: boolean;
  intervalId: number;
}

class Layout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      thePosition: false,
      intervalId: 0,
    };
  }

  public componentDidMount() {
    document.addEventListener("scroll", () => {
      if (typeof window !== 'undefined' && window.scrollY > 170) {
          this.setState({ thePosition: true })
      } else {
          this.setState({ thePosition: false })
      }
    });
    typeof window !== 'undefined' && window.scrollTo(0, 0);
  }
  
  public onScrollStep = () => {
    const { intervalId } = this.state;
    const { scrollStepInPx } = this.props;

    if (typeof window !== 'undefined' && window.pageYOffset === 0){
        clearInterval(intervalId);
    }
    typeof window !== 'undefined' && window.scroll(0, window.pageYOffset - scrollStepInPx);
  }
  
  public scrollToTop = () => {
    const { delayInMs } = this.props;

    let intervalIdNum = window.setInterval(this.onScrollStep, delayInMs);
    this.setState({
      intervalId: intervalIdNum
    });
  }


  render() {
    const { children, headerElement } = this.props;
    const goToTop = (
      <a id="scrollTop" className="scroll-to" onClick={this.scrollToTop}>
        <i className="fa fa-chevron-up"></i><i className="fa fa-chevron-up"></i>
      </a>
    );

    return (
      <>  
        <Helmet>    
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
          <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
          <link rel="apple-touch-icon" sizes="57x57" href="../images/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="../images/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="../images/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="../images/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="../images/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="../images/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="../images/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="../images/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="../images/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192"  href="../images/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="../images/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="../images/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="../images/favicon-16x16.png" />
          <link rel="manifest" href="../images/manifest.json" />
          <link rel="icon" href="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/favicon.ico" />
        </Helmet>
        <Header siteTitle="The African Nova Scotian Decade for People of African Descent Coalition" headerType={headerElement} />
        <div>
          <main>{children}</main>
        </div>
        { this.state.thePosition && goToTop}
        <ANSDPADFooter />
      </>
    )
  }
}

export default Layout
