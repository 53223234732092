import React, { Component } from "react"

class ANSDPADFooter extends Component {
    render() {
        return (
            <footer id="footer" className="inverted">
                <div className="footer-content">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 text-center">
                        <div className="widget">
                        <h4 className="dpad-text-color">About DPAD</h4>
                        <ul className="list">
                            <li><a href="/about">DPAD Timeline</a></li>
                            <li><a href="/dpad-news">DPAD News</a></li>
                            <li><a href="/membership">Membership</a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 text-center">
                        <div className="widget">
                        <h4 className="dpad-text-color">Our Work</h4>
                        <ul className="list">
                            <li><a href="/community-service-wg">Community Services Working Group</a></li>
                            <li><a href="/health-working-group">Health Strategy Working Group</a></li>
                            <li><a href="/justice-working-group">Justice Strategy Working Group</a></li>
                        </ul>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-4 text-center">
                        <div className="widget">
                        <h4 className="dpad-text-color">Other Pages</h4>
                        <ul className="list">
                            <li><a href="https://ansa.novascotia.ca/sites/default/files/dpad-action-plan.pdf">ANSA Action Plan</a></li>
                            <li><a href="https://www.un.org/en/observances/decade-people-african-descent">UN Decade</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="widget clearfix widget-newsletter">
                        <h4 className="widget-title dpad-text-color">Sign Up For a Newsletter</h4>
                        <p>Weekly breaking news regarding the ANS Community <span className="badge badge-danger p-2"> Coming soon </span>

                        </p>
                        <form className="widget-subscribe-form p-r-40" action="include/subscribe-form.php" role="form" method="post">
                            <div className="input-group">
                                <input aria-required="true" name="widget-subscribe-form-email" className="form-control required email" placeholder="Enter your Email" type="email" />
                                <span className="input-group-btn">
                                    <button type="submit" id="widget-subscribe-submit-button" className="btn"><i className="fa fa-chevron-right"></i></button>
                                </span>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="copyright-content text-white">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="copyright-text text-center">&copy; 2024 The African Nova Scotian Decade for People of African Descent Coalition. All Rights Reserved.</div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
        );
    }
}

export default ANSDPADFooter;