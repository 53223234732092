import React, { Component, Fragment } from "react"
import WhiteLogo from './whiteLogo';

interface Props {
  siteTitle: string;
  headerType: JSX.Element;
}

type State = {
    prevScrollpos: number,
    sticky: boolean
    openMobileMenu: boolean;
    openAboutSubMenu: boolean;
    openOurWork: boolean;
    openOurPages: boolean;
}

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const prevScroll = typeof window !== 'undefined' && window.pageYOffset;

    this.state = {
        prevScrollpos: prevScroll ? prevScroll : 0,
        sticky: false,
        openMobileMenu: false,
        openAboutSubMenu: true,
        openOurWork: true,
        openOurPages: true,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleMenuSelection = this.handleMenuSelection.bind(this);
    this.handleAboutSubMenu = this.handleAboutSubMenu.bind(this);
    this.handleOurWorkSubMenu = this.handleOurWorkSubMenu.bind(this);
    this.handleOurPagesSubMenu = this.handleOurPagesSubMenu.bind(this);
  }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        typeof window !== 'undefined' && window.addEventListener("scroll", this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        typeof window !== 'undefined' && window.removeEventListener("scroll", this.handleScroll);
    }

    public handleScroll(): void {
        const { prevScrollpos } = this.state;
        const currentScrollPos = window.pageYOffset;
        const stickyVal = prevScrollpos > currentScrollPos;

        this.setState({
            prevScrollpos: currentScrollPos,
            sticky: stickyVal
        });
    }

    public handleMenuSelection(): void {
        const { openMobileMenu } = this.state;

        this.setState({
            openMobileMenu: !openMobileMenu,
        });
    }

    public handleAboutSubMenu(): void {
        const { openAboutSubMenu } = this.state;

        this.setState({
            openAboutSubMenu: !openAboutSubMenu,
        });
    }

    public handleOurWorkSubMenu(): void {
        const { openOurWork } = this.state;

        this.setState({
            openOurWork: !openOurWork,
        });
    }

    public handleOurPagesSubMenu(): void {
        const { openOurPages } = this.state;

        this.setState({
            openOurPages: !openOurPages,
        });
    }

  render() {
    const { prevScrollpos, openMobileMenu, openAboutSubMenu, openOurPages, openOurWork } = this.state;
    const { headerType } = this.props;
    
    return (
      <Fragment>
        <div id="topbar" className="d-none d-xl-block d-lg-block topbar-transparent topbar-fullwidth dark">
            {/* <div className="call-to-action call-to-action-colored text-light background-black" style={{padding: '14px 36px 14px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h3 style={{color: '#FCB616 !important'}}><a href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html" style={{color: '#FCB616'}}>Important information on COVID-19</a></h3>
                            <p style={{fontSize: 16}}>For the most up-to-date information on COVID-19 and information on precautionary measures for you and your family visit the Nova Scotia Government website.</p>
                            <div className="col-lg-12 p-0 text-center"><a type="button" href="https://novascotia.ca/coronavirus/" className="btn btn-rounded">Nova Scotia Government Website</a> </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 d-none d-sm-block">
                        <div className="social-icons social-icons-colored-hover">
                            <ul>
                                <li className="social-facebook"><a href="https://www.facebook.com/African-Nova-Scotian-Decade-for-People-of-African-Descent-Coalition-103200967849187/" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                <li className="social-twitter"><a href="https://twitter.com/ansdpad" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li className="social-instagram"><a href="https://instagram.com/ansdpad" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                <li className="social-linkedin"><a href="https://www.linkedin.com/company/ansdpad" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        {/* <a href="/international-black-health-conference" className="anshc-heading">International Black Health Conference</a> */}
                    </div>
                </div>
            </div>
        </div>
        <header id="header" data-transparent="true" data-fullwidth="true" className={`dark submenu-light ${(prevScrollpos > 0) ? 'header-sticky sticky-active' : ''}`}>
          <div className={`header-inner ${openMobileMenu && 'menu-bg'}`}>
              <div className="container">
                  <div id="logo">
                      <a href="/">
                          <span className="logo-dark" style={{width: 325, marginTop: '-13px'}}>
                            <WhiteLogo />
                          </span>
                      </a>
                  </div>
                  <div id="search"><a id="btn-search-close" className="btn-search-close" aria-label="Close search form"><i className="icon-x"></i></a>
                      <form className="search-form" action="search-results-page.html" method="get">
                          <input className="form-control" name="q" type="search" placeholder="Type & Search..." />
                          <span className="text-muted">Start typing & press "Enter" or "ESC" to close</span>
                      </form>
                  </div>
                  <div id="mainMenu-trigger">
                      <a className="lines-button x" onClick={this.handleMenuSelection}><span className={`lines ${openMobileMenu ? 'dark-text-color' : 'white-text-color'}`}></span></a>
                  </div>
                  <div id="mainMenu" className={`menu-creative ${openMobileMenu && 'menu-animate full-width'}`}>
                      <div className="container">
                          <nav>
                              <ul>
                                  <li><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="/">Home</a></li>
                                  <li className={`dropdown ${openMobileMenu && (openOurWork && 'hover-active')}`}><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="#" onClick={this.handleAboutSubMenu}>About DPAD</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="/about-dpad" className={`${openMobileMenu && 'dark-text-color'}`}>Our Mission and Vision</a></li>
                                        <li><a href="/about" className={`${openMobileMenu && 'dark-text-color'}`}>DPAD Timeline</a></li>
                                        <li><a href="/dpad-news" className={`${openMobileMenu && 'dark-text-color'}`}>DPAD News</a></li>
                                        <li><a href="/membership" className={`${openMobileMenu && 'dark-text-color'}`}>Membership</a></li>
                                    </ul>
                                  </li>
                                  <li className={`dropdown ${openMobileMenu && (openOurWork && 'hover-active')}`}><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="#" onClick={this.handleOurWorkSubMenu}>Our Work</a>
                                      <ul className="dropdown-menu">
                                        <li><a href="/community-service-wg" className={`${openMobileMenu && 'dark-text-color'}`}>Community Services Working Group</a></li>
                                        <li><a href="/health-working-group" className={`${openMobileMenu && 'dark-text-color'}`}>Health Strategy Working Group</a></li>                                        
                                        <li><a href="/justice-working-group" className={`${openMobileMenu && 'dark-text-color'}`}>Justice Strategy Working Group</a></li>
                                      </ul>
                                  </li>
                                  <li className={`dropdown ${openMobileMenu && (openOurPages && 'hover-active')}`}><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="#" onClick={this.handleOurPagesSubMenu}>Partners</a>
                                    <ul className="dropdown-menu">
                                        <li><a href="/ansji" className={`${openMobileMenu && 'dark-text-color'}`}>ANSJI</a></li>
                                        <li><a href="/pan-canadian-black-food-sovereignty-caucus" className={`${openMobileMenu && 'dark-text-color'}`}>The Pan-Canadian Black Food Sovereignty Caucus</a></li>
                                        <li><a href="https://www.un.org/en/observances/decade-people-african-descent" className={`${openMobileMenu && 'dark-text-color'}`} target="_blank">UN Decade</a></li>
                                        <li><a href="https://ansa.novascotia.ca/sites/default/files/dpad-action-plan.pdf" className={`${openMobileMenu && 'dark-text-color'}`} target="_blank">ANSA Action Plan</a></li>
                                    </ul>
                                  </li>
                                  {/* <li className={`dropdown ${openMobileMenu && (openOurPages && 'hover-active')}`}><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="#" onClick={this.handleOurPagesSubMenu}>Pages</a>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown"><a href="#" className={`${openMobileMenu && 'dark-text-color'}`}>Our Members<span className="badge badge-success">Coming soon</span></a></li>
                                        <li className="dropdown"><a href="#" className={`${openMobileMenu && 'dark-text-color'}`}>Activities<span className="badge badge-success">Coming soon</span></a></li>
                                        <li className="dropdown"><a href="#" className={`${openMobileMenu && 'dark-text-color'}`}>Archives<span className="badge badge-success">Coming soon</span></a></li>
                                    </ul>
                                  </li> */}
                                  <li className="dropdown"><a className={`${(prevScrollpos > 0) ? 'dpad-text-color' : ''} ${openMobileMenu && 'dark-text-color'}`} href="/contact">Contact Us</a></li>
                              </ul>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
      </header>
      {headerType}
      </Fragment>
    );
  }
}

export default Header
